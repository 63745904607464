<template>
    <div :class="['gradient-bar-container', {reverse : reverse}]" :style="styleObject">
            <div class="gradient-bar"></div>
    </div>
</template>

<script>
export default {
    name : "GradientBar",
    props : ["reverse", "height"],
    data() {
        return {
            styleObject : {
                height : this.height + "px"
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.gradient-bar-container {
    width:100px;
    margin-left:20px;
    display: flex;
    justify-content: center;
}

.reverse.gradient-bar-container  {
    margin-left:0;
    margin-right:30px;
}

.gradient-bar {
    width:5px;
    border-radius:5px;
    background:$verticalGradient;
    height:100%;
    transform: rotate(10deg);
}

.reverse .gradient-bar {
    transform: rotate(-10deg);
}

@media screen and (max-width:768px) {
    .gradient-bar-container {
        display: none;
    }
}

</style>