<template>
    <div class="banner">
        <Particles
                id="tsparticles"
                :particlesInit="particlesInit"
                :particlesLoaded="particlesLoaded"
                :options="{
                    background: {
                        color: {
                            value: 'transparent'
                        }
                    },
                    fpsLimit: 120,
                    interactivity: {
                        events: {
                            onClick: {
                                enable: false,
                                mode: 'push'
                            },
                            onHover: {
                                enable: false,
                                mode: 'repulse'
                            },
                            resize: true
                        },
                        modes: {
                            bubble: {
                                distance: 400,
                                duration: 2,
                                opacity: 0.8,
                                size: 40
                            },
                            push: {
                                quantity: 4
                            },
                            repulse: {
                                distance: 200,
                                duration: 0.4
                            }
                        }
                    },
                    particles: {
                        color: {
                            value: ['#3687c8', '#b51f83']                     
                        },
                        links: {
                            color: '#ffffff',
                            distance: 150,
                            enable: false,
                            opacity: 0.5,
                            width: 1
                        },
                        collisions: {
                            enable: true
                        },
                        move: {
                            direction: 'none',
                            enable: true,
                            outModes: {
                                default: 'bounce'
                            },
                            random: false,
                            speed: 0.3,
                            straight: false
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 1200
                            },
                            value: 80
                        },
                        opacity: {
                            value: 0.5
                        },
                        shape: {
                            type: 'circle'
                        },
                        size: {
                            value: 2,
                            random: true,
                            animation: {
                                enable: true,
                                speed: 10,
                                minimumValue: 0.1,
                                sync: false
                            },
                            move: {
                                enable: true,
                            },
                        },
                        animation: {
                            enable: true,
                            speed: 20,
                            minimumValue: 0.1,
                            sync: false
                        },
                    },
                    detectRetina: true
                }"
        />
        <div class="box-container title-container">
            <gradient-bar :reverse="true" height="250" ></gradient-bar>
            <h1>Découvrez nos propres<br /> collections originales</h1>
        </div>
    </div>
    <!--- COLLECTION 1 -->
    <div class="box-container collections-container">
        <div class="col-50">
            <div class="collection-img-container">
                <img src="./../assets/logo-made-in-france.png" class="collection-logo" alt="logo made in france" />
                <img src="./../assets/made-in-france.gif" class="collection-img" alt="made in france" />
            </div>
        </div>
        <div class="col-50">
            <div class="collections-item">
                <p>collection</p>
                <h2>Made in france</h2>
                <a href="https://www.madeinfrance.one/" target="_blank" class="btn">découvrir la collection</a>
            </div>
        </div>
    </div>
    <!--- COLLECTION 2 -->
    <div class="box-container collections-container reverse">
        <div class="col-50">
            <div class="collection-img-container">
                <img src="./../assets/logo-stand-for-ukraine.png" class="collection-logo" alt="logo stand-for-ukraine" />
                <img src="./../assets/stand-for-ukraine.gif" class="collection-img" alt="stand-for-ukraine" />
            </div>
        </div>
        <div class="col-50">
            <div class="collections-item">
                <p>collection</p>
                <h2>stand-for-ukraine</h2>
                <a href="https://stand-for-ukraine.org/" target="_blank" class="btn">découvrir la collection</a>
            </div>
        </div>
    </div>
    <!--- COLLECTION 3 -->
    <div class="box-container collections-container last">
        <div class="col-50">
            <div class="collection-img-container">
                <img src="./../assets/logo-cryptovirtua.png" class="collection-logo" alt="logo cryptovirtua" />
                <img src="./../assets/cryptovirtua.gif" class="collection-img" alt="cryptovirtua" />
            </div>
        </div>
        <div class="col-50">
            <div class="collections-item">
                <p>collection</p>
                <h2>cryptovirtua</h2>
                <a href="https://cryptovirtua.com/nft" target="_blank" class="btn">découvrir la collection</a>
            </div>
        </div>
    </div>
    
</template>

<script>
import GradientBar from "./../components/GradientBar.vue";
// import Popper from "vue3-popper";

export default {
    name : "Collections",
    components : {
        GradientBar,
        // Popper
    },
    metaInfo: {
        title: 'Créateur & Editeur de collections NFT',
        meta: [
            { name: 'description', content: 'Born2Mint lance ses propres collections de NFT et certaines sont notamment destinées à soutenir des Causes' },
        ]
    },
}
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
    width: 100%;
  margin-left:-50px;
}

h1 {
  color:#fff;
  font-size:60px;
  font-weight: 600;
  text-align: right;
  text-transform: uppercase;
  margin:0;
  letter-spacing: 2px;
}

.collections-container {
    display: flex;
    align-items: center;
    padding:120px 0;
}

.collections-container.last {
    padding-bottom:20px;
}

.collections-container.reverse {
    flex-direction: row-reverse;
}

.collection-img-container {
    width:450px;
    max-width: 80%;
    margin:0 auto;
    position:relative;
}

.collection-img-container .collection-img {
    max-width: 100%;
    width:100%;
}

.collection-logo {
    position:absolute;
    top:-75px;
    left:-74px;
    width:150px;
    height:auto;
}

.reverse .collection-logo {
    left:auto;
    right:-74px;
}

.collections-item {
    width:450px;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:0 auto;
}

.collections-item p {
    color:#fff;
    font-weight:600;
    text-transform: uppercase;
    font-size:25px;
    margin: 0;
}

.collections-item h2 {
    font-weight:700;
    font-size:35px;
    text-transform: uppercase;
    background: $mainGradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 10px 0 40px 0;
}

@media screen and (max-width:768px) { 
    h1 {
        font-size:40px;
        text-align: center;
    }

    .title-container {
       margin: 0 auto;
    } 

    .collections-container, .collections-container.reverse {
        flex-direction: column;
    }

    .collection-logo {
        width:100px;
        left:-50px;
        top:-50px;
    }

    .collection-img-container {
        max-width: 250px;
        display: flex;
        justify-content: center;
    }

    .collections-item {
        margin-top:20px;
    }

    .reverse .collection-logo {
        left:auto;
        right:-50px;
    }

}

</style>